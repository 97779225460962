

@media all and (max-width: 450px) {
    iframe{
        max-width: 100% !important;
    }
}
    
iframe{
    width: 470px;
}
@media all and (min-width: 960px){
.lg\:max-w-none {
    max-width: none;
    margin-top: 3rem;
}}
.mtred{
    margin-top: 1.6rem;
    height: 50px;

}

@media (min-width: 375px){
.sm\:text-4xl {
    font-size: 2.25rem;
    line-height: 2.9rem;
    letter-spacing: 1px;
    font-family:'Courier New', Courier, monospace
}
}

.subs{
    border: none !important;
}