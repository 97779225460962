.cv{
    margin-top: 10px;
    width: 132px;
    background-color: rgb(255, 0, 115);
    color: aliceblue;
}
.cv:hover{
    background-color: aliceblue;
    color: rgb(255, 0, 115);
    transition: all .5s ease-in-out;
    
}