.img-responsive{
    width: 550px;
    height: auto;
    z-index: 99;
    border: none;
    margin: 0;
    padding: 0;
    position: relative;
    border-image: round;
    border-radius: 10px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.6);
    transition: all 0.9s ease-in-out !important;
}
body,html{
    overflow-x: hidden;
}
.img-responsive:hover{
    transform: scale(1.1);
    transition: all 0.9s ease-in-out !important;
}

@media all and (max-width:500px){
    .shd{
       position: relative;
       right: 2rem;
    }
}