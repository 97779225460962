ol, ul, menu {
    list-style: none;
    margin: 45px;
    padding: 0;
}
.transit {
    transition: all 0.9s ease-in-out !important;
  }

  .logo{
    width: 80px;
    margin: 0 auto;
    display: block;
    transition: all 0.9s ease-in-out !important;
    animation: logo 3s infinite  linear ;  ;
  }
  @keyframes logo {
    0% { 
      transform: rotate(0deg); 
    }
    100% { 
      transform: rotate(390deg);
    }
   }
  